var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: { name: "Menu Groups" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          _c(
            "CForm",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { col: "12" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Name",
                          lazy: false,
                          value: _vm.$v.form.Name.$model,
                          isValid: _vm.checkIfValid("Name"),
                          placeholder: "Name",
                          invalidFeedback:
                            "This is a required field and must be at least 2 characters",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.Name, "$model", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { col: "12" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Notes",
                          lazy: false,
                          value: _vm.$v.form.Notes.$model,
                          placeholder: "Notes",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.Notes, "$model", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardFooter",
        [
          _c(
            "CButton",
            {
              attrs: { color: "primary", disabled: _vm.submitted },
              on: {
                click: function ($event) {
                  _vm.isEdit ? _vm.update() : _vm.store()
                },
              },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }